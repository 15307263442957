<template>
  <div class="pd20x">
    <AddAccount :visible="visible" :data="currentItem" @close="visible = false"></AddAccount>
    <div class="filter" style="padding:15px 0">
        <div> 已推送（5条）</div>
        <div>
            <label>学员姓名：</label>
            <a-input-search placeholder="请输入搜索内容" style="width: 200px" @search="onSearch" class="mr10px"/>
            <label>推送状态：</label>
            <a-select default-value="0" style="width: 120px" @change="handleChange">
                <a-select-option value="0">
                    发送成功
                </a-select-option>
                <a-select-option value="1">
                    发送失败
                </a-select-option>
            </a-select>
        </div>
    </div>
    <a-table
        :columns="column"
        :data-source="data"
        :rowKey="'id'"
    >
        <template slot="edit" slot-scope="text">
          <i class="z-icon edit" @click="visible = true; currentItem = text"></i>
        </template>
        <template slot="operation">
            <span class="csP mr20px" style="color:#7ebae5">重置密码</span>
              <span class="csP" style="color:#7ebae5">删除</span>
        </template>
    </a-table>
  </div>
</template>
<script>
const column = [
    {
      title: '学员',
      dataIndex: 'username',
      align:'center'
    }, {
      title: '推送时间',
      dataIndex: 'subauth',
      align:'center'
    }, {
      title: '推送条数',
      dataIndex: 'edit',
      scopedSlots: { customRender: 'edit' },
      align:'center'
    },{
      title: '推送状态',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' },
      align:'center'
    },
];
import * as http from '@/libs/examapi'
import AddAccount from '@/components/exam/addAccount'
export default {
  name: 'tsSet',
  components: { AddAccount },
  data(){
    return{
      column,
      data:[],
      visible: false,
      currentItem: null,
      pagion:{
        page: 1,
        size: 10
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.getData()
    })
  },
  methods:{
    getData(){
      let request = {
        ...this.pagion,
        gid: this.$store.getters.permission
      }
      http.get_users(request).then(res=>{
        this.data = res.list
        console.log(res)
      }).catch(error=>{
        console.log(error)
      })
    },
    onSearch(){

    }
  }
}
</script>
