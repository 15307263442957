<template>
  <div class="pd20x">
    <AddAccount :visible="visible" :data="currentItem" @close="visible = false" @update="getData"></AddAccount>
    <div class="filter" style="padding:15px 0">
      <div> 账号数目（5条）</div>
      <div > <a-button type="primary" @click="visible = true; currentItem = null"> 新增账户 </a-button></div>
    </div>
    <a-table
        :columns="column"
        :data-source="data"
        :rowKey="'id'"
        :pagination="pagination"
        @change="handleTableChange"
    >
        <template slot="edit" slot-scope="text,record">
          <i class="z-icon edit" @click="visible = true; currentItem = record"></i>
        </template>
        <template slot="subauth" slot-scope="text">
            {{transfromAuth(text)}}
        </template>
        <template slot="operation" slot-scope="text,record">
            <span class="csP mr20px" style="color:#7ebae5" @click="editOpen = true; currentItem = record">重置密码</span>
              <span class="csP" style="color:#7ebae5" @click="deleteThis(record)">删除</span>
        </template>
    </a-table>
    <a-modal
      title="重置密码"
      :visible="editOpen"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="editOpen = false"
    >
     <a-form v-bind="formItemLayout">
        <a-form-item label="密码">
          <a-input type="password" v-model="password" placeholder="请输入密码" />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input type="password" v-model="surepassword"  placeholder="请输入确认密码" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
const column = [
    {
      title: '账号',
      dataIndex: 'username',
      align:'center'
    }, {
      title: '权限',
      dataIndex: 'subauth',
      scopedSlots: { customRender: 'subauth' },
      align:'center'
    }, {
      title: '编辑账目',
      dataIndex: 'edit',
      scopedSlots: { customRender: 'edit' },
      align:'center'
    },{
      title: '操作',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' },
      align:'center'
    },
];
import * as http from '@/libs/examapi'
import AddAccount from '@/components/exam/addAccount'
export default {
  name: 'accountSet',
  components: { AddAccount },
  data(){
    return{
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      column,
      data:[],
      visible: false,
      currentItem: null,
      confirmLoading: false,
      pagination:{
        page: 1,
        size: '15',
        pageSize: 15
      },
      editOpen: false,
      password: '',
      surepassword: ''
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.getData()
    })
  },
  methods:{
    handleTableChange(pagination){
      const pager = { ...this.pagination };
      this.pagination.page = pagination.current;
      this.getData()
    },
    transfromAuth(data){
      let  defaultV =[
        { label:'展示营销', value:'1' },
        { label:'课程管理', value:'2' },
        { label:'学员管理', value:'3' },
        { label:'订单管理', value:'4' }
      ]
      let filter = []
      data.split(',').forEach(el => {
        filter.push(defaultV.filter(item=> item.value == el)[0].label) 
      });
      return filter.join("，")
    },
    getData(){
      let request = {
        ...this.pagination,
        gid: this.$store.getters.permission
      }
      http.get_users(request).then(res=>{
          const pagination = { ...this.pagination }
          pagination.total = res.total;
          this.data = res.data
         this.pagination = pagination;
      }).catch(error=>{
        console.log(error)
      })
    },
    deleteThis({id}){
      let _this = this
      this.$confirm({
          title: '是否确定删除该条信息?',
          centered: true,
          onOk() {
            http.del_user({id}).then(res=>{
              if(_this.data.length == 1 && _this.pagination.page != 1){
                _this.pagination.page = Number(_this.pagination.page)-1
                _this.getData()
              }else{
                 _this.getData()
              }
            }).catch(error=>{
              console.log(error)
            })
              
          },
      });
    },
    handleOk(){
      if(this.password != this.surepassword){
          this.$message.error('两次密码不同，请重新输入')
          return 
      }
      http.edit_password({id:this.currentItem.id,password: this.password}).then(res=>{
        this.editOpen = false
        this.password = ''
        this.surepassword = ''
      }).catch(error=>{
        console.log(error)
      })
    }
  }
}
</script>
