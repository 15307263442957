<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="radio-line">
                <div class="radio-line-item" v-for="(item, index) in tabList" :key="index" :class="{active: index == activeIndex}" @click="activeIndex = index">{{item}}</div>
            </div>
            <div v-if="activeIndex == 0">
                <accountSet></accountSet>
            </div>
            <div v-if="activeIndex == 1" class="pd20x">
                <div class="filter" style="padding:15px 0">
                    <div>学习提醒设置</div>
                </div>
                <div class="boxShadow" style="padding:20px;color:#888">
                    有效期余额不足<a-input placeholder="0" type="number" style="width:200px;margin:0 10px"/>天的学员
                </div>
            </div>
            <div v-if="activeIndex == 2">
                <tsSet></tsSet>
            </div>
            <div v-if="activeIndex == 3" style="padding:20px;color:#888">
                <label for="">添加微信：</label><a-input style="width:400px;margin:0 10px"/>
            </div>
        </div>
    </div>
</template>
<script>
import accountSet from './accountSet'
import tsSet from './tsSet'
export default {
    name: 'basicSet',
    components:{accountSet, tsSet},
    data(){
        this.tabList = ['账号设置', '提醒设置', '推送设置', '教务微信设置']
        return{
            activeIndex: 0
        }
    }
}
</script>
<style lang="less" scoped>
.radio-line{
    padding:10px 20px 0;
    .radio-line-item{
        margin-right: 50px;
    }
}
</style>